import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import axios from "axios";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Grid from '@mui/material/Grid';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from "jspdf";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const countryCodeMapping = {
  "AF": "Afghanistan",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "VG": "British Virgin Islands",
  "BN": "Brunei",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CO": "Colombia",
  "KM": "Comoros",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "HR": "Croatia",
  "CU": "Cuba",
  "CW": "Curaçao",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "CD": "Democratic Republic of the Congo",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GU": "Guam",
  "GT": "Guatemala",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IL": "Israel",
  "IT": "Italy",
  "CI": "Ivory Coast",
  "JM": "Jamaica",
  "JP": "Japan",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "XK": "Kosovo",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Laos",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macau",
  "MK": "Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "KP": "North Korea",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestine",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "CG": "Republic of the Congo",
  "RO": "Romania",
  "RU": "Russia",
  "RW": "Rwanda",
  "BL": "Saint Barthélemy",
  "SH": "Saint Helena",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "MF": "Saint Martin",
  "PM": "Saint Pierre and Miquelon",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "São Tomé and Príncipe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SX": "Sint Maarten",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "KR": "South Korea",
  "SS": "South Sudan",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syria",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks and Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VA": "Vatican",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "VI": "Virgin Islands",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
};

const ApproveArticle = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const role = localStorage.getItem('role');
  const [searchTerm, setSearchTerm] = useState('');
  const eventid = localStorage.getItem('eventid')
  const [permissions, setrPermissions] = useState([]);
  const AuthUserName = localStorage.getItem('auth_name');
  const [abstractArticle, setAbstractArticle] = React.useState([]);
  const [aproveArticleEdit, setAproveArticleEdit] = useState({
    id: '',
    title: '',
    description: '',
    image: '',
    attachment: '',
    video_link: '',
    status: '',
    upload_status: '',
    review_status: '',
    created_by: '',
    reject: '',
    start_date: '',
    categoy: '',
  });
  // model single data show
  //for model open'
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenForEdit] = useState(false);
  //for Single Record  show
  const [singleData, setSingleData] = useState({
    id: '',
    title: '',
    description: '',
    event_id: "",
    status: "",
    image: '',
  });
  const [category, setCategory] = React.useState('');
  const [abstractCategory, setAbstractCategory] = React.useState([]);

  React.useEffect(() => {
    axios.get(`api/getpermissionByRole`).then(res => {

      if (res.status === 200) {
        const datad = res.data.data.permissions;
        setrPermissions(datad)
      } else {

      }
    })

  }, []);

  // React.useEffect(() => {
  //   axios.get(`api/abstractApprovleArticle?id=${eventid}`).then(res => {

  //     if (res.status === 200) {
  //       const abstractArticleData = res.data.article;

  //       setAbstractArticle(abstractArticleData)

  //     } else {
  //       swal('Error', "Check API Something Wrong", 'error');

  //     }
  //   })

  // }, []);

  // const filteredPosts = abstractArticle.filter((row) =>
  //   row.category.title && row.category.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   row.submission.title && row.submission.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   row.title && row.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   // Add more fields as needed
  // );
  // const totalRecords = abstractArticle.length;
  // const filteredRecordsCount = filteredPosts.length;


  // useEffect(() => {
  //   axios.get(`api/abstractApprovleArticle?id=${eventid}`)
  //     .then(res => {
  //       if (res.status === 200) {
  //         const abstractArticleData = res.data.article;

  //         // Ensure the data is an array before setting state
  //         // if (Array.isArray(abstractArticleData)) {
  //         //   setAbstractArticle(abstractArticleData);
  //         // }
  //         if (Array.isArray(abstractArticleData)) {
  //           const processedData = abstractArticleData.map(article => {
  //             const totalScores = article.rating.reduce((sum, rating) => sum + rating.total_scores, 0);
  //             const obtainScores = article.rating.reduce((sum, rating) => sum + rating.obtain_scores, 0);

  //             return {
  //               ...article,
  //               scores: {
  //                 totalScores,
  //                 obtainScores
  //               }
  //             };
  //           });

  //           setAbstractArticle(processedData);
  //         }


  //         else {
  //           console.error('Expected array but received:', abstractArticleData);
  //           setAbstractArticle([]);
  //         }
  //       } else {
  //         swal('Error', "Check API Something Wrong", 'error');
  //       }
  //     })
  //     .catch(error => {
  //       console.error('API error:', error);
  //       swal('Error', "Something went wrong with the API request", 'error');
  //     });
  // }, [eventid]);

  useEffect(() => {
    axios.get(`api/abstractApprovleArticle?id=${eventid}`)
      .then(res => {
        if (res.status === 200) {
          const abstractArticleData = res.data.article;

          if (Array.isArray(abstractArticleData)) {
            const processedData = abstractArticleData.map(article => {

              const totalScores = article.rating.reduce((sum, rating) =>
                sum + parseFloat(rating.abstract_total_score), 0);

              const obtainScores = article.rating.reduce((sum, rating) =>
                sum + parseFloat(rating.abstract_obtain_scores), 0);

              const reviewCount = article.count_reviewed_count || 1;

              const averageTotalScores = totalScores / reviewCount;
              const averageObtainScores = obtainScores / reviewCount;

              return {
                ...article,
                scores: {
                  totalScores,
                  obtainScores,
                  averageTotalScores,
                  averageObtainScores
                }
              };
            });

            setAbstractArticle(processedData);
          } else {
            console.error('Expected array but received:', abstractArticleData);
            setAbstractArticle([]);
          }
        } else {
          swal('Error', "Check API Something Wrong", 'error');
        }
      })
      .catch(error => {
        console.error('API error:', error);
        swal('Error', "Something went wrong with the API request", 'error');
      });
  }, [eventid]);

  // Ensure abstractArticle is an array before calling filter
  const filteredPosts = (abstractArticle || []).filter((row) =>
    (row.category?.title && row.category.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.submission?.title && row.submission.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.title && row.title.toLowerCase().includes(searchTerm.toLowerCase()))
    // Add more fields as needed
  );
  console.log("abstractArticle", abstractArticle)
  const totalRecords = (abstractArticle || []).length;
  const filteredRecordsCount = filteredPosts.length;

  const handleClickShowSingleRecord = (id) => {
    axios.get(`api/abstractArticleShow/${id}`).then(res => {

      if (res.status === 200) {
        const data = res.data;
        setSingleData(data)

      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    })
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };


  const headers = [
    { label: 'Category Title', key: 'category.title' },
    { label: 'Submission Title', key: 'submission.title' },
    { label: 'Writer Name', key: 'Writer Name' },

    { label: 'Writer Email', key: 'Writer Email' },
    { label: 'Writer Phone', key: 'Writer Phone' },
    { label: 'Writer Country', key: 'Writer Country' },

    { label: 'Name Of Peresenter', key: 'Name Of Peresenter' },
    { label: 'Name Of Authors', key: 'Name Of Authors' },

    { label: 'Abstrct Title', key: 'Abstrct Title' },
    { label: 'Abstrct Summary', key: 'Abstrct Summary' },
    { label: 'Conclusion', key: 'Conclusion' },


    { label: 'Introduction Background', key: 'Introduction Background' },
    { label: 'Materials Methods', key: 'Materials Methods' },
    { label: 'Results', key: 'Results' },
    { label: 'Video Link', key: 'Video Link' },
    { label: 'Total Scores', key: 'Total Scores' },
    { label: 'Obtain Scores', key: 'Obtain Scores' },

    { label: 'Average Total Scores', key: 'Average Total Scores' },
    { label: 'Average Obtain Scores', key: 'Average Obtain Scores' },




    // Add more headers as needed
  ];
  const handleDownloadCSV = () => {
    const headers = [
      'Writer Name',
      'Writer Email',
      'Writer Phone',
      'Writer Country',
      'Name Of Peresenter',
      'Name Of Authors',
      'Abstrct Title',
      'Category Title',
      'Submission Title',
      'Abstrct Summary',
      'Conclusion',
      'Introduction Background',
      'Materials Methods',
      'Results',
      'Video Link',
      'Total Scores',
      'Obtain Scores',
      'Average Total Scores',
      'Average Obtain Scores',

    ];

    const escapeCsvValue = (value) => {
      if (value === null || value === undefined) return '';
      // Replace double quotes with two double quotes and enclose in double quotes
      return `"${value.toString().replace(/"/g, '""')}"`;
    };

    const csvContent = [
      headers.join(','),
      ...filteredPosts.map((row) =>
        [
          escapeCsvValue(row.writer.name),
          escapeCsvValue(row.writer.email),
          escapeCsvValue(row.writer.phone),
          escapeCsvValue(`${countryCodeMapping[row.writer.country_code] || ""}`),
          escapeCsvValue(row.nameofpresenter),
          escapeCsvValue(row.nameofauthors),
          escapeCsvValue(row.title),
          escapeCsvValue(row.category.title),
          escapeCsvValue(row.submission.title),
          escapeCsvValue(row.description), // Ensure this is properly escaped
          escapeCsvValue(row.conclusion),
          escapeCsvValue(row.introduction_background),
          escapeCsvValue(row.materials_methods),
          escapeCsvValue(row.results),
          escapeCsvValue(row.video_link),
          escapeCsvValue(row.scores.totalScores),
          escapeCsvValue(row.scores.obtainScores),
          escapeCsvValue(row.scores.averageTotalScores),
          escapeCsvValue(row.scores.averageObtainScores),
        ].join(',')
      ),
    ].join('\n');

    // const blob = new Blob([csvContent], { type: 'text/csv' });
    // const url = window.URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'Approved-Abstract.csv');
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    const blob = new Blob([`\uFEFF${csvContent}`], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Approved-Abstract.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //pdf 
  const handlePrint = () => {
    window.print();
  };


  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    let yOffset = 10;

    doc.text(`Title: ${singleData.title}`, 10, yOffset);
    yOffset += 10;

    doc.text(`Author: ${singleData.writer_name}`, 10, yOffset);
    yOffset += 20;

    if (singleData.image !== "no_image.jpg") {
      const img = new Image();
      img.src = `${singleData.base_url}/abstract/${singleData.image}`;
      img.crossOrigin = "Anonymous"; // Ensure cross-origin images are allowed

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imgData = canvas.toDataURL('image/jpeg');
        doc.addImage(imgData, 'JPEG', 10, yOffset, 180, 160);
        yOffset += 170;
        addRemainingContent(doc, yOffset);
        doc.save(`${singleData.writer_name}.pdf`);
      };

      img.onerror = () => {
        console.error("Error loading image");
        addRemainingContent(doc, yOffset);
        doc.save(`${singleData.writer_name}.pdf`);
      };
    } else {
      yOffset += 10;
      addRemainingContent(doc, yOffset);
      doc.save(`${singleData.writer_name}.pdf`);
    }
  };

  const addRemainingContent = (doc, yOffset) => {
    doc.text(`Video Link: ${singleData.video_link}`, 10, yOffset);
    yOffset += 10;

    if (singleData.reject !== null) {
      doc.text(`Reject Reason: ${singleData.reject}`, 10, yOffset);
      yOffset += 20;
    }

    doc.text(`Description: ${singleData.description}`, 10, yOffset);
    yOffset += 20;

    doc.text(`Publish Date: ${singleData.start_date}`, 10, yOffset);
    yOffset += 20;

    if (singleData.review_status === 1) {
      doc.text('Review Status: Approve', 10, yOffset);
    } else if (singleData.review_status === 2) {
      doc.text('Review Status: Pending', 10, yOffset);
    } else {
      doc.text(`Review Status: Reject - Rejected By: ${singleData.reviewer_name}`, 10, yOffset);
    }
    yOffset += 20;

    if (singleData.status === 1) {
      doc.text('Status: Active', 10, yOffset);
    } else {
      doc.text('Status: Inactive', 10, yOffset);
    }
  };

  // const handleDownloadCSV = () => {
  //   const headers = [
  //     'Writer Name',
  //     'Writer Email',
  //     'Writer Phone',

  //     'Name Of Peresenter',
  //     'Name Of Authors',
  //     'Abstrct Title',
  //     'Category Title',
  //     'Submission Title',
  //     'Abstrct Summary',
  //     'Conclusion',

  //     'Introduction Background',
  //     'Materials Methods',
  //     'results',
  //     'Video Link',

  //     'Obtain Marks',
  //     'Total Marks',

  //     // Add more headers as needed
  //   ];

  //   const csvContent = [
  //     headers.join(','),
  //     ...filteredPosts.map((row) =>
  //       [
  //         row.writer.name,
  //         row.writer.email,
  //         row.writer.phone,

  //         `"${row.nameofpresenter}"`,
  //         `"${row.nameofauthors}"`,
  //         `"${row.title}"`,  // Enclose the title in double quotes

  //         row.category.title,
  //         row.submission.title,
  //         `"${row.description}"`,
  //         `"${row.conclusion}"`,

  //         `"${row.introduction_background}"`,
  //         `"${row.materials_methods}"`,
  //         `"${row.results}"`,
  //         `"${row.video_link}"`,

  //         row.rating?.abstract_obtain_scores || '0', // Add this line
  //         row.rating?.abstract_total_score || '0'

  //         // Add more fields as needed
  //       ].join(',')
  //     ),
  //   ].join('\n');

  //   const blob = new Blob([csvContent], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'Approved-Abstract.csv');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };


  const handleClickEditRecord = (id) => {
    axios.get(`api/abstractArticleEdit/${id}`).then(res => {
      if (res.status === 200) {
        const data = res.data.abstractEdit;
        setAproveArticleEdit(data)
      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    })
    setOpenForEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenForEdit(false);
  };
  const handleInput = (e) => {
    e.persist();
    setAproveArticleEdit({ ...aproveArticleEdit, [e.target.name]: e.target.value });
  }

  //Categorie
  React.useEffect(() => {
    axios.get(`api/abstractCategory?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractCategoryData = res.data.articleCategory;
        setAbstractCategory(abstractCategoryData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  const handleChange = (e) => {
    setCategory(e.target.value);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - abstractArticle.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = parseInt(event.target.value, 10);
    // Update rows per page or show all records if value is -1
    setRowsPerPage(value > 0 ? value : filteredPosts.length);
    setPage(0); // Reset to the first page
  };
  
  const abstractDelete = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = " Deleting..."
    axios.get(`api/abstractArticlestatus/${id}`).then(res => {
      if (res.data.status === 200) {
        thisClicked.closest("tr").remove();
      }
      else if (res.data.status === 404) {
        this.innText = "delete";
      }
    });

  }

  let backGroundButtonByEvent = "";
  if (eventid == 63) {
    backGroundButtonByEvent = '#1EB5B0'; // Assign the value conditionally
  }
  else {
    backGroundButtonByEvent = '#145348'; // Default background color
  }
  return (
    <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>

        <Card sx={{ width: '82vw', float: 'center' }} >

          <CardContent >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography component="h1" variant="h5">
                Approved Abstract
              </Typography>
              Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
              <Grid item xs={8} >
                <TextField
                  fullWidth
                  label="Filter By Category And Type"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid>
            </div>
            {

              role === 'organiser' ?
                <Button onClick={handleDownloadCSV} sx={{
                  float: 'right',
                  marginRight: '1%',
                  backgroundColor: backGroundButtonByEvent,
                  marginTop: '2%',
                  ":hover": {
                    color: "white"
                  }
                }}
                  color='success'
                  size='small'
                  variant='contained'>
                  <FileDownloadIcon />
                  Download CSV
                </Button> : ""
            }

            {
              permissions['p_abstract-article-approve-create'] === true ?

                <Button sx={{
                  float: 'right',
                  marginRight: '1%',
                  backgroundColor: backGroundButtonByEvent,
                  marginTop: '2%',
                  ":hover": {
                    color: "white"
                  }
                }}
                  color='success'
                  size='small'
                  variant='contained'
                  component={Link}
                  to="/admin/abstract/add-approve-article">
                  Add New
                </Button> : ""}

            <Box component="form" noValidate sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow sx={{ color: '#FFFFFF' }}>
                      <TableCell > #</TableCell>
                      <TableCell align="left"> Title</TableCell>
                      <TableCell align="left">Category</TableCell>
                      <TableCell align="left">Type</TableCell>
                      {/* <TableCell align="left">Issue</TableCell> */}
                      <TableCell align="left">Submit Status</TableCell>
                      <TableCell align="left">Review Status</TableCell>
                      <TableCell align="center">Status</TableCell>


                      <TableCell align="left">All Review</TableCell>

                      <TableCell align="center" style={{ width: 150 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {(rowsPerPage > 0
                      ? filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : filteredPosts
                    ).map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" style={{ width: 52 }}>
                          {i + 1}
                        </TableCell>
                        <TableCell style={{ width: 60 }} align="left">
                          {row.title}
                        </TableCell>
                        <TableCell style={{ width: 60 }} align="left">

                          {row.category.title}
                        </TableCell>
                        <TableCell style={{ width: 80 }} align="left" >
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}> {row.submission.title}</Typography>
                          {/* {
                            row.type === 1 ?
                              <Typography variant="h6" sx={{ color: '#009688', fontWeight: 'bold' }}>Podium</Typography> :
                              row.type === 2 ?
                                <Typography variant="h6" sx={{ color: '#1b5e20', fontWeight: 'bold' }}>Poster</Typography> :
                                row.type === 3 ?
                                  <Typography variant="h6" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Poster</Typography> :
                                  row.type === 4 ?
                                    <Typography variant="h6" sx={{ color: '#795548', fontWeight: 'bold' }}>Workshop Paper</Typography> :
                                    row.type === 5 ?
                                      <Typography variant="h6" sx={{ color: '#3e2723', fontWeight: 'bold' }}> Full Research Paper</Typography> :
                                      row.type === 6 ?
                                        <Typography variant="h6" sx={{ color: '#607d8b', fontWeight: 'bold' }}>Competition</Typography> :
                                        row.type === 7 ?
                                          <Typography variant="h6" sx={{ color: '#827717', fontWeight: 'bold' }}>Research Award</Typography> :
                                          <Typography variant="h6" sx={{ color: '#d50000', fontWeight: 'bold' }}>N:A</Typography>


                          } */}
                        </TableCell >

                        <TableCell style={{ width: 80 }} align="left" >

                          {(() => {
                            if (row.upload_status === 1) {
                              return (
                                <>  <h4>New Uploaded</h4>
                                </>
                              )
                            }
                            else {
                              return (
                                <> <h4>updated Uploaded</h4>
                                </>
                              )
                            }

                          })
                            ()}
                        </TableCell>
                        <TableCell style={{ width: 60 }} align="center">

                          {/* <Badge badgeContent={row.review_status} color="success">
                       </Badge> */}
                          {(() => {
                            if (row.review_status === 1) {
                              return (
                                <> <Badge badgeContent={'Approve'} color="success">
                                </Badge>
                                </>
                              )
                            }
                            else if (row.review_status === 2) {
                              return (
                                <> <Badge badgeContent={'pending'} color="primary">
                                </Badge>
                                </>
                              )
                            }
                            else {
                              return (
                                <> <Badge badgeContent={'reject'} color="secondary">
                                </Badge>
                                </>
                              )
                            }

                          })
                            ()}
                        </TableCell>
                        <TableCell style={{ width: 60 }} align="center">

                          {(() => {
                            if (row.status === 1) {
                              return (
                                <> <Badge badgeContent={'Active'} color="success">
                                </Badge>
                                </>
                              )
                            }
                            else {
                              return (
                                <> <Badge badgeContent={'Inactive'} color="secondary">
                                </Badge>
                                </>
                              )
                            }

                          })
                            ()}
                        </TableCell>


                        <TableCell style={{ width: 60 }} align="left">
                          <Button variant="contained" size="small"
                            color='primary'
                            size='small'
                            // onClick={(e) => handleClickEditRecord(row.id)}
                            component={Link}
                            to={`/admin/abstract/all-review/${row.id}`} sx={{ backgroundColor: backGroundButtonByEvent, }}>
                            Score
                          </Button >
                        </TableCell>

                        <TableCell style={{ width: 150 }} align="right">

                          <Button sx={{
                            ":hover": {
                              color: "white"
                            }
                            ,
                            backgroundColor: backGroundButtonByEvent,
                          }}
                            color='primary'
                            size='small'
                            variant='contained'
                            onClick={(e) => handleClickShowSingleRecord(row.id)}>
                            <VisibilityIcon />
                          </Button>

                          {/* <Button sx={{
                            margin: 1,

                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained'
                           
                            component={Link}
                            to={`/admin/abstract/edit-approve-article/${row.id}`}>
                            <EditNoteIcon />
                          </Button> */}

                          {
                            permissions['p_abstract-article-approve-edit'] === true ?
                              role === 'Author' ?
                                <Button sx={{
                                  margin: 1,

                                  ":hover": {
                                    color: "white"
                                  },
                                  backgroundColor: '#145348'
                                }}
                                  color='primary'
                                  size='small'
                                  variant='contained'
                                  // onClick={(e) => handleClickEditRecord(row.id)}
                                  component={Link}
                                  to={`/admin/author/abstract/edit-approve-article/${row.id}`}>
                                  <EditNoteIcon />
                                </Button> :

                                <Button sx={{
                                  margin: 1,

                                  ":hover": {
                                    color: "white"
                                  },
                                  backgroundColor: backGroundButtonByEvent,
                                }}
                                  color='primary'
                                  size='small'
                                  variant='contained'
                                  // onClick={(e) => handleClickEditRecord(row.id)}
                                  component={Link}
                                  to={`/admin/abstract/edit-approve-article/${row.id}`}>
                                  <EditNoteIcon />
                                </Button> : ""}
                          {
                            permissions['p_abstract-article-approve-delete'] === true ?
                              <Button sx={{
                                ":hover": {
                                  color: "white"
                                },
                                backgroundColor: backGroundButtonByEvent,
                              }}
                                color='primary'
                                size='small'
                                variant='contained'
                                onClick={(e) => abstractDelete(e, row.id)}>
                                <DeleteIcon />
                              </Button> : ""}
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={filteredPosts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {/* Show Single Recrod */}
      <style>
        {`
        @media print {
          .dialog-actions, .print-button {
            display: none;
          }

          .dialog-title {
            display: none;
          }
        }
      `}
      </style>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Title: {singleData.title}
          <br />
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Author: {singleData.writer_name}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Approved By: {singleData.approved_name}
        </DialogTitle>
        {
          singleData.image === "no_image.jpg" ? "" :
            <DialogTitle id="alert-dialog-title">
              <img src={`${singleData.base_url}/abstract/${singleData.image}`} width="500" height="400" />
            </DialogTitle>
        }
        {
          singleData.attachment === "no_file.pdf" ? "" :
            <DialogTitle id="alert-dialog-title">
              {singleData.attachment ?
                <a href={`${singleData.base_url}/abstract/${singleData.attachment}`}
                  download={`${singleData.base_url}/abstract/${singleData.attachment}`}
                  target="_blank" rel="noreferrer">Download Attachment</a> : <Button>No Attachment</Button>}
            </DialogTitle>
        }

        <DialogTitle id="alert-dialog-title">
          {singleData.video_link}
        </DialogTitle>
        <p style={{ padding: 25 }}>
          Descriptions: {singleData.description}
        </p>
        <DialogTitle id="alert-dialog-title">
          Publish Date :{singleData.start_date}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Review Status
          {(() => {
            if (singleData.review_status === 1) {
              return (

                <Badge badgeContent={'Approve'} color="success" style={{ paddingRight: 35 }}>
                </Badge>

              )
            }
            else if (singleData.review_status === 2) {
              return (
                <> <Badge badgeContent={'Pending'} color="primary" style={{ paddingRight: 35 }}>
                </Badge>
                </>
              )
            }
            else {
              return (
                <> <Badge badgeContent={'Reject'} color="secondary" style={{ paddingRight: 35 }}>
                </Badge>
                </>
              )
            }

          })
            ()}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Status  {(() => {
            if (singleData.status === 1) {
              return (
                <> <Badge badgeContent={'Active'} color="success" style={{ paddingRight: 35 }}>
                </Badge>
                </>
              )
            }
            else {
              return (
                <> <Badge badgeContent={'Inactive'} color="secondary" style={{ paddingRight: 35 }}>
                </Badge>
                </>
              )
            }

          })
            ()}
        </DialogTitle>
        <DialogActions className="dialog-actions">
          <IconButton onClick={handleClose} >
            <CancelIcon />
          </IconButton>
          <Button className="print-button" onClick={handlePrint}>Print</Button>
          <Button onClick={handleDownloadPDF}>Download as PDF</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit Record of ID: {aproveArticleEdit.id}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Title: {aproveArticleEdit.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please, edit the Abstract of your Requrments.
          </DialogContentText>
          <br />
          <TextField
            margin="dense"
            autoFocus
            id="firstName"
            label="Title"
            type="text"
            fullWidth
            name='title'
            onChange={handleInput} value={aproveArticleEdit.title} />


          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel >Role</InputLabel>
              <Select
                name='role' onChange={handleChange} defaultValue={aproveArticleEdit.category}
                label="Role">

                {
                  abstractCategory.map((item => {
                    return (
                      <MenuItem value={item.id} key={item.id} >{item.title}</MenuItem>
                    )

                  }))
                }

              </Select>
            </FormControl>
          </Grid>
          <TextField
            margin="dense"
            id="descriptions"
            label="Add Descriptions"
            name='description'
            multiline
            fullWidth
            rows={6}
            required
            autoFocus
            onChange={handleInput} value={aproveArticleEdit.description}
            sx={{ mt: 2 }} />


          <Grid item sx={{ mt: 2 }}>
            <label htmlFor="icon-button-file">
              <TextField id="icon-button-file" type="file" name="[licenseFile]" />
              <IconButton color="primary" aria-label="upload picture" component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <label htmlFor="contained-button-file">
              <TextField id="contained-button-file" multiple type="file" />

              <IconButton color="primary" aria-label="upload picture" component="span"
              >
                <AttachmentIcon />
              </IconButton>
            </label>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <TextField
              autoComplete="given-name"
              required
              fullWidth
              label="Youtube Video ID"
              name='youtubelink'
              autoFocus
              onChange={handleInput} value={aproveArticleEdit.video_link} />
          </Grid>

          {/* <Grid item xs={6} sx={{ mt: 2 }}>
            <TextField
              type="date"
              label="Publish Date"
              required
              fullWidth
              autoFocus
              name='start_date'  value={aproveArticleEdit.start_date}/>

          </Grid> */}

          {/* <FormControl
            margin="dense"
            fullWidth sx={{ mt: 2 }} >

            <InputLabel >Review Status</InputLabel >
            <Select
              name='review_status'
              label="Review Status"  onChange={handleInput} value={aproveArticleEdit.review_status}>

              <MenuItem value={1} >Approve</MenuItem>
              <MenuItem value={2}>Pending</MenuItem>
              <MenuItem value={3}>Reject</MenuItem>
            </Select>
          </FormControl> */}

          {/* <FormControl
            margin="dense"
            fullWidth sx={{ mt: 2 }} >

            <InputLabel >Status</InputLabel>
            <Select
              name='status'
              label="Status"
              onChange={handleInput} value={aproveArticleEdit.status}
            >

              <MenuItem value={1} >Active</MenuItem>
              <MenuItem value={0}>InActive</MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            margin="dense"
            id="descriptions"
            label="Article Reject"
            name='article_reject'
            multiline
            fullWidth
            rows={6}
            required
            sx={{ mt: 2 }}
            onChange={handleInput} value={aproveArticleEdit.reject} />


        </DialogContent>
        <DialogActions >
          <Button onClick={handleCloseEdit} color="primary">
            Cancel
          </Button>
          <Button color="primary">
            Submit
          </Button>
        </DialogActions>
        <DialogActions>
          {/* <IconButton onClick={handleCloseEdit} >
            <CancelIcon />
          </IconButton> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ApproveArticle;
